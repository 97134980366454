<template>
  <q-page v-if="bundles || errors" padding>
    <virtual-list
      v-if="filtered.length > 0"
      :size="size"
      :remain="12"
      wclass="wrapper"
      class="col-sm-12 flights-container"
    >
      <div class="info-bar">
        <div class="flex justify-between">
          <div>
            <m-group-title
              :label="$t('flight.choose.flights')"
              class="title"
            />
            <p class="text-grey-8 text-body2">
              {{ stash.parameters.resultsDescription }}
            </p>
          </div>
          <div class="text-grey-8 text-body2">
            <div class="text-right text-bold">
              <span>
                {{ labels.origin }} - {{ labels.destination }},
              </span>
              <span v-if="stash.parameters.inbound">
                {{ labels.destination }} - {{ labels.origin }}
              </span>
            </div>
            <div class="text-right">
              {{ shortDepart }} - {{ shortReturn }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <q-btn-group class="sort q-mt-md text-faded" flat size="sm">
          <q-btn
            flat
            :label="$t('sort_by')"
            size="sm"
            disable
            class="cursor-none"
          />
          <q-btn
            flat
            :label="$t('price')"
            size="sm"
            :class="sortBy === 'price' ? 'bg-blue-grey-1' : null"
            class="first"
            @click="sortBy = 'price'"
          />
          <q-btn
            flat
            :label="$t('time.travel')"
            size="sm"
            :class="sortBy === 'duration' ? 'bg-blue-grey-1' : null"
            @click="sortBy = 'duration'"
          />
          <q-btn
            flat
            :label="$t('time.from')"
            size="sm"
            :class="sortBy === 'outbound.depart' ? 'bg-blue-grey-1' : null"
            @click="sortBy = 'outbound.depart'"
          />
        </q-btn-group>
      </div>
      <div class="results">
        <div
          v-for="(bundle, i) in filtered"
          :key="bundle.pricing_token"
        >
          <result
            :result="bundle"
            @click="setFlight"
          />
          <help-card v-if="i === 2" />
        </div>
      </div>
    </virtual-list>
    <m-empty-state
      v-else
      icon="flight_takeoff"
    >
      <h4 class="text-faded text-center">
        {{ $t('no_flights_found') }}
      </h4>
      <h6 class="text-faded text-center">
        {{ $t('check_narrow_results') }}
      </h6>
    </m-empty-state>
    <div v-if="filtered.length > 0" class="cta">
      <m-cta-button
        :label="stash.selected && stash.selected.outbound ? $t('continue') : $t('select_an_option_to_continue')"
        :disabled="!stash.selected.outbound"
        @ctaClick="$router.push({name: 'ondemand-flights-passenger-information'})"
      />
    </div>
  </q-page>
</template>

<script type='text/javascript'>
import date from 'utils/date-time'
import { handleErrors } from 'utils/utils'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import store from 'store'
import { search } from 'api/flights'
import virtualList from 'vue-virtual-scroll-list'
import result from './result'
import helpCard from '../../shared/help-card'
import i18n from 'i18n'
import { MGroupTitle, MEmptyState, MCtaButton } from 'components/'

async function getFlights () {
  const partner = store.getters['partner']

  const stash = store.getters['ondemand/stash']
  const parameters = { ...stash.parameters }
  if (!stash.selected) {
    store.dispatch('ondemand/stash', { selected: {} })
  }
  parameters.user = parameters.traveller.value
  parameters.requester = parameters.requester.value

  loading.start({
    message: i18n.t('loading.searching.flight'),
    partnerSlug: partner.slug,
    spinnerColor: partner.hex
  })

  const response = await search(parameters)

  // filter out return filters for a single flight
  // then restructure the data for quasar select field
  response.meta.filters.sort_by = response.meta.filters.sort_by
    .filter(s => !stash.parameters.inbound ? !s.text.includes('Return') : true)
    .map(s => ({ label: s.text, value: s.key }))

  store.dispatch('ondemand/filters', {
    ...response.meta.filters
  })

  // Set the default active filters to affect initial display
  const filters = store.getters['ondemand/filters']
  const activeFilters = {}
  activeFilters.airlines = filters.airlines.map(a => a.code)
  activeFilters.sort_by = 'price'
  store.dispatch('ondemand/activeFilters', activeFilters)
  return response.data
}

export default {
  name: 'OndemandFlightsResults',
  components: {
    result,
    virtualList,
    helpCard,
    MGroupTitle,
    MEmptyState,
    MCtaButton
  },
  props: {
    direction: {
      type: String,
      required: true,
      validator (value) {
        return ['outbound', 'return'].includes(value)
      }
    }
  },
  data () {
    return {
      bundles: null,
      errors: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      filters: 'ondemand/filters',
      activeFilters: 'ondemand/activeFilters'
    }),
    sortBy: {
      get () {
        return this.activeFilters.sort_by
      },
      set (value) {
        this.$store.dispatch('ondemand/activeFilters', {
          ...this.activeFilters,
          sort_by: value
        })
      }
    },
    size () {
      return !this.stash.parameters.inbound ? 134 : 268
    },
    shortDepart () {
      return date.toWeekdayDate(this.stash.parameters.outbound)
    },
    shortReturn () {
      const inbound = this.stash.parameters.inbound
      return inbound ? date.toWeekdayDate(inbound) : null
    },
    filtered () {
      const filteredBundles = this.bundles
        // Filter based on provider
        .filter(b => {
          let vendors = b.outbound.vendors
          if (b.return) {
            vendors = vendors.concat(b.return.vendors)
          }

          const vendorCodes = vendors.map(v => v.vendor_code)
          return vendorCodes
            .every(code => this.activeFilters.airlines
              .includes(code))
        })

        .filter(b => {
          const outboundDepartHour = b.outbound.depart.date.split(' ')[1].split(':')[0]
          const outboundArriveHour = b.outbound.arrive.date.split(' ')[1].split(':')[0]
          const outboundValid = outboundDepartHour >= this.stash.parameters.outboundDepartureMin &&
            outboundDepartHour <= this.stash.parameters.outboundDepartureMax &&
            outboundArriveHour >= this.stash.parameters.outboundArrivalMin &&
            outboundArriveHour <= this.stash.parameters.outboundArrivalMax

          const hasReturn = !!b.return
          let inboundValid = false

          if (hasReturn) {
            const returnDepartHour = b.return.depart.date.split(' ')[1].split(':')[0]
            const returnArriveHour = b.return.arrive.date.split(' ')[1].split(':')[0]
            inboundValid = returnDepartHour >= this.stash.parameters.inboundDepartureMin &&
              returnDepartHour <= this.stash.parameters.inboundDepartureMax &&
              returnArriveHour >= this.stash.parameters.inboundArrivalMin &&
              returnArriveHour <= this.stash.parameters.inboundArrivalMax
          }
          return hasReturn ? outboundValid && inboundValid : outboundValid
        })

        .sort((a, b) => {
          if (this.activeFilters.sort_by === 'duration') {
            const aTotal = a.outbound.duration.value + (a.return ? a.return.duration.value : 0)
            const bTotal = b.outbound.duration.value + (b.return ? b.return.duration.value : 0)
            if (aTotal > bTotal) return 1
            if (aTotal < bTotal) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'price') {
            if (a.price.amount > b.price.amount) return 1
            if (a.price.amount < b.price.amount) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'outbound.arrive') {
            a = date.newDate(a.outbound.arrive.date)
            b = date.newDate(b.outbound.arrive.date)
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'return.arrive') {
            a = date.newDate(a.return.arrive.date)
            b = date.newDate(b.return.arrive.date)
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'outbound.depart') {
            a = date.newDate(a.outbound.depart.date)
            b = date.newDate(b.outbound.depart.date)
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'return.depart') {
            a = date.newDate(a.return.depart.date)
            b = date.newDate(b.return.depart.date)
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'outbound.duration') {
            if (a.outbound.duration.value > b.outbound.duration.value) return 1
            if (a.outbound.duration.value < b.outbound.duration.value) return -1
            return 0
          }
          if (this.activeFilters.sort_by === 'return.duration') {
            if (a.return.duration.value > b.return.duration.value) return 1
            if (a.return.duration.value < b.return.duration.value) return -1
            return 0
          }
        })

      let description
      if (filteredBundles.length !== this.bundles.length) {
        description = this.$tc('showing_num_of_total_flights', filteredBundles.length, { num: filteredBundles.length, total: this.bundles.length })
      } else {
        description = this.$tc('showing_all_num_flights', this.bundles.length)
      }

      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.resultsDescription',
        value: description
      })

      return filteredBundles
    },
    labels () {
      const origin = this.stash.parameters.origin
      const destination = this.stash.parameters.destination
      return {
        origin: origin.value.length <= 3 ? origin.value : origin.label,
        destination: destination.value.length <= 3 ? destination.value : destination.label
      }
    }
  },
  watch: {
    async 'stash.parameters.max_changes' (newVal, oldVal) {
      if (newVal !== oldVal) {
        try {
          const bundles = await getFlights()
          this.bundles = bundles
          loading.stop()
        } catch (err) {
          this.errors = err.data
          this.outbound = null
          this.inbound = null
          this.$q.notify(this.$t('error.default'))
          loading.stop()
        }
      }
    }
  },
  methods: {
    setFlight (bundle) {
      store.dispatch('ondemand/stash', { selected: bundle })
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const bundles = await getFlights()

      next(vm => {
        vm.bundles = bundles
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang='stylus' scoped>
.flights-container
  height calc(100vh - 210px)!important
  @media (min-width 600px)
    height calc(100vh - 140px)!important
 >>> .wrapper
  margin: auto
  max-width 720px

.bundle, .title
  margin auto
  margin-bottom 16px
  margin-top 16px

.results
  margin auto

.info-bar
  position relative
  z-index 25
  padding 24px 8px 16px
  border-bottom 1px solid
  margin auto
  border-color $grey-4
.title
  margin 0

.sort
  border 1px solid
  border-color $grey-4
</style>
