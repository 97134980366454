<template>
  <q-page v-if="summary" padding>
    <q-card v-if="showPassengerInfo" class="bg-white">
      <q-card-section :class="`bg-primary`">
        {{ $t('passenger_information') }}
      </q-card-section>
      <q-card-section class="row q-col-gutter-sm">
        <div class="col-sm-12 q-col-gutter-sm row">
          <div v-if="isRequired('passport_title')" class="col-6">
            <q-input
              v-model="passengerInfo.passport_title"
              :error="stash.error && !passengerInfo.passport_title"
              stack-label
              :label="$t('title')"
            />
          </div>
          <div v-if="isRequired('passport_first_name')" class="col-6">
            <q-input
              v-model="passengerInfo.passport_first_name"
              :error="stash.error && !passengerInfo.passport_first_name"
              stack-label
              :label="$tc('name.given', 2)"
            />
          </div>
          <div v-if="isRequired('passport_last_name')" class="col-6">
            <q-input
              v-model="passengerInfo.passport_last_name"
              :error="stash.error && !passengerInfo.passport_last_name"
              type="text"
              stack-label
              :label="$t('name.surname')"
            />
          </div>

          <div v-if="isRequired('date_of_birth')" class="col-6">
            <m-date-field
              v-model="passengerInfo.date_of_birth"
              :error="stash.error && !passengerInfo.date_of_birth"
              :label="$t('date.birth')"
            />
          </div>
          <div v-if="isRequired('nationality')" class="col-6">
            <q-select
              v-model="passengerInfo.nationality"
              :error="stash.error && !passengerInfo.nationality"
              :options="nationalitySelect"
              filter
              emit-value
              map-options
              stack-label
              use-input
              :label="$t('nationality')"
              @filter="nationalitySelectFilter"
            />
          </div>
          <div v-if="isRequired('gender')" class="col-6">
            <q-select
              v-model="passengerInfo.gender"
              :error="stash.error && !passengerInfo.gender"
              :options="[{label: $t('sex.male'), value: $t('abbreviation.male')}, {label: $t('sex.female'), value: $t('abbreviation.female')}]"
              stack-label
              emit-value
              map-options
              :label="$t('sex.sex')"
            />
          </div>
          <div v-if="isRequired('passport_number')" class="col-6">
            <q-input
              v-model="passengerInfo.passport_number"
              :error="stash.error && !passengerInfo.passport_number"
              type="text"
              stack-label
              :label="$t('passport.number')"
            />
          </div>
          <div v-if="isRequired('passport_expiry')" class="col-6">
            <m-date-field
              v-model="passengerInfo.passport_expiry"
              :error="stash.error && !passengerInfo.passport_expiry"
              :label="$t('passport.expiry')"
            />
          </div>
          <div v-if="isRequired('passport_issuing_country')" class="col-6">
            <q-select
              v-model="passengerInfo.passport_issuing_country"
              :error="stash.error && !passengerInfo.passport_issuing_country"
              :options="countrySelect"
              filter
              emit-value
              map-options
              stack-label
              use-input
              :label="$t('passport.issuing_country')"
              @filter="countrySelectFilter"
            />
          </div>
        </div>
      </q-card-section>
      <m-banner v-if="summary.passport_info_notice" :message="summary.passport_info_notice" :rounded="false" borderless />
    </q-card>
    <q-card
      v-if="showUSAInfo"
      class="bg-white full-width"
    >
      <q-card-section :class="`bg-primary`">
        {{ $t('tooltip.usa_travel') }}
      </q-card-section>
      <q-card-section class="row q-col-gutter-sm">
        <!-- large, country of residence, city of residence, first line address, city, state, zip code-->
        <div
          class="col-sm-12 q-col-gutter-sm row"
        >
          <div v-if="isRequired('country_of_residence')" class="col-6">
            <q-select
              v-model="passengerInfo.country_of_residence"
              :error="stash.error && !passengerInfo.country_of_residence"
              :options="countrySelect"
              filter
              emit-value
              stack-label
              map-options
              use-input
              :label="$t('country_of_residence')"
              @filter="countrySelectFilter"
            />
          </div>
          <div v-if="isRequired('city_of_residence')" class="col-6">
            <q-input
              v-model="passengerInfo.city_of_residence"
              :error="stash.error && !passengerInfo.city_of_residence"
              stack-label
              :label="$t('city_of_residence')"
            />
          </div>
          <div v-if="isRequired('destination_address')" class="col-6">
            <q-input
              v-model="passengerInfo.destination_address"
              :error="stash.error && !passengerInfo.destination_address"
              stack-label
              :label="$t('address_staying_line_one')"
            />
          </div>
          <div v-if="isRequired('destination_city')" class="col-6">
            <q-input
              v-model="passengerInfo.destination_city"
              :error="stash.error && !passengerInfo.destination_city"
              stack-label
              :label="$t('city_where_staying')"
            />
          </div>
          <div v-if="isRequired('destination_state')" class="col-6">
            <q-select
              v-model="passengerInfo.destination_state"
              :error="stash.error && !passengerInfo.destination_state"
              :options="statesList"
              filter
              emit-value
              map-options
              stack-label
              :label="$t('state_where_you_are_staying')"
            />
          </div>
          <div v-if="isRequired('destination_zip')" class="col-6">
            <q-input
              v-model="passengerInfo.destination_zip"
              :error="stash.error && !passengerInfo.destination_zip"
              stack-label
              :label="$t('zip_code_where_staying')"
            />
          </div>
        </div>
      </q-card-section>
      <!-- america notice -->
      <m-banner v-if="summary.us_extra_info_notice" :message="summary.us_extra_info_notice" :rounded="false" borderless />
    </q-card>
    <q-card class="bg-white">
      <q-card-section :class="`bg-primary`">
        {{ $t('contact.information') }}
      </q-card-section>
      <q-card-section class="row q-col-gutter-sm">
        <!-- extra, post code, contact number, email address -->
        <div class="col-sm-12 q-col-gutter-sm row">
          <div v-if="isRequired('post_code')" class="col-6">
            <q-input
              v-model="passengerInfo.post_code"
              :error="stash.error && !passengerInfo.post_code"
              type="text"
              stack-label
              :label="$t('post_code')"
            />
          </div>
          <div v-if="isRequired('phone_number')" class="col-6">
            <div class="row">
              <div class="col">
                <m-phone-field
                  :dialcode="phone.callingCode"
                  :phone="phone.number"
                  :phone-error="phone.error"
                  @dialcode="val => phone.callingCode = val"
                  @phone="val => phone.number = val"
                />
              </div>
            </div>
          </div>
          <div v-if="isRequired('email')" class="col-6">
            <q-input
              v-model="passengerInfo.email"
              :error="stash.error && !passengerInfo.email"
              type="email"
              stack-label
              :label="$t('email_address')"
            />
          </div>
        </div>
      </q-card-section>
      <!-- contact details notice -->
      <m-banner v-if="summary.extra_info_notice" :message="summary.extra_info_notice" :rounded="false" borderless />
    </q-card>
    <div class="cta">
      <m-cta-button
        :label="$t('continue')"
        @ctaClick="$router.push({name: 'ondemand-flights-extras'})"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import _ from 'lodash'
import date from 'utils/date-time'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import { summary } from 'api/flights'
import { mapGetters } from 'vuex'
import store from 'store'
import usStates from 'store/us-states'
import { get } from 'api/users'
import { nationalities, countries } from 'utils/quasar-countries'
import handlePhoneNumber from 'utils/phone'
import { isValidNumber } from 'libphonenumber-js'
import { MBanner, MDateField, MPhoneField, MCtaButton } from 'components/'
import i18n from 'i18n'

var countrySelect = countries
var nationalitySelect = nationalities

function alphabeticalSort (a, b) {
  return a.label.charCodeAt(0) - b.label.charCodeAt(0)
}

countrySelect.sort(alphabeticalSort)
nationalitySelect.sort(alphabeticalSort)

function setPassengerDefaults (passengerInfo, user) {
  if (!user.meta.passport_information) {
    user.meta.passport_information = {}
  }

  passengerInfo.email = user.email
  passengerInfo.post_code = user.meta.post_code
  passengerInfo.country_of_residence = user.meta.country_of_residence
  passengerInfo.city_of_residence = user.meta.city_of_residence
  passengerInfo.passport_title = user.meta.passport_information.passport_title
  passengerInfo.passport_first_name = user.meta.passport_information.passport_first_name
  passengerInfo.passport_last_name = user.meta.passport_information.passport_last_name
  passengerInfo.date_of_birth = user.meta.passport_information.date_of_birth
  passengerInfo.gender = user.meta.passport_information.gender
  passengerInfo.nationality = user.meta.passport_information.nationality
  passengerInfo.passport_expiry = user.meta.passport_information.passport_expiry
  passengerInfo.passport_issuing_country = user.meta.passport_information.passport_issuing_country
  passengerInfo.passport_number = user.meta.passport_information.passport_number
}
export default {
  name: 'OnDemandFlightsPassengerInformation',
  components: {
    MDateField,
    MBanner,
    MPhoneField,
    MCtaButton
  },
  data () {
    return {
      phone: {
        callingCode: '44',
        number: null,
        error: false
      },
      countrySelect,
      nationalitySelect,
      summary: null,
      passengerInfo: null,
      requiredFields: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    showPassengerInfo () {
      return this.isRequired('passport_title') || this.isRequired('passport_first_name') ||
      this.isRequired('passport_last_name') || this.isRequired('date_of_birth') ||
      this.isRequired('nationality') || this.isRequired('gender') ||
      this.isRequired('passport_number') || this.isRequired('passport_expiry') ||
      this.isRequired('passport_issuing_country')
    },
    showUSAInfo () {
      return this.isRequired('country_of_residence') || this.isRequired('city_of_residence') ||
      this.isRequired('destination_address') || this.isRequired('destination_city') ||
      this.isRequired('destination_state') || this.isRequired('destination_zip')
    },
    showContactInfo () {
      return this.isRequired('post_code') || this.isRequired('phone_number') ||
      this.isRequired('email')
    },
    errors () {
      if (this.stash.errors) {
        return Object.keys(this.stash.error.data.errors).map(k => {
          return this.stash.error.data.errors[k][0]
        })
      } else {
        return null
      }
    }
  },
  methods: {
    isRequired (field) {
      return this.requiredFields.hasOwnProperty(field)
    },
    countrySelectFilter (val, update) {
      if (val) {
        update(() => {
          this.countrySelect = countrySelect.filter(({ label }) => {
            return label.toLowerCase().startsWith(val.toLowerCase())
          })
        })
      }
    },
    nationalitySelectFilter (val, update) {
      if (val) {
        update(() => {
          this.nationalitySelect = nationalitySelect.filter(({ label }) => {
            return label.toLowerCase().startsWith(val.toLowerCase())
          })
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.preparing.booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })
    const stash = store.getters['ondemand/stash']

    try {
      const [res, user] = await Promise.all([
        summary(stash.selected.pricing_token),
        get(stash.parameters.traveller.value)
      ])

      const member = user.data

      next(vm => {
        vm.statesList = usStates.map(s => ({ label: s.name, value: s['alpha-2'] }))
        vm.summary = res.data
        vm.passengerInfo = _.cloneDeep(vm.summary.required_passenger_information)
        vm.requiredFields = vm.summary.required_passenger_information
        setPassengerDefaults(vm.passengerInfo, member)

        store.dispatch('ondemand/stashProp', {
          path: 'stash.fareRules',
          value: vm.summary.fare_rules
        })

        const { countryCallingCode, nationalNumber } = handlePhoneNumber(member.meta.mobile)
        vm.phone.callingCode = countryCallingCode
        vm.phone.number = nationalNumber

        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name.includes('extras')) {
      const phoneNumber = '+' + this.phone.callingCode + this.phone.number

      if (isValidNumber(phoneNumber)) {
        this.phone.error = false
        this.passengerInfo.phone_number = phoneNumber

        if (this.passengerInfo.date_of_birth) {
          this.passengerInfo.date_of_birth = date.toCivilDate(this.passengerInfo.date_of_birth)
        }

        if (this.passengerInfo.passport_expiry) {
          this.passengerInfo.passport_expiry = date.toCivilDate(this.passengerInfo.passport_expiry)
        }

        store.dispatch('ondemand/stash', { passengerInfo: this.passengerInfo }).then(() => {
          next()
        })
      } else {
        this.phone.error = true
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>
.extras
  max-width 768px
  margin auto
.q-card-primary + .q-card-section
  padding-top 16px
.q-card-dark > *
  font-size 14px!important
</style>
