<template>
  <q-card v-ripple class="shadow-1 row result" :class="cardClass" @click.native="select">
    <div class="journeys col-12 col-sm-8">
      <q-card-section class="flight-journey">
        <journey-summary :journey="result.outbound" />
      </q-card-section>
      <q-card-section v-if="result.return" class="flight-journey">
        <journey-summary :journey="result.return" />
      </q-card-section>
    </div>
    <div class="price col-12 col-sm-4 column q-pt-md q-px-md justify-between text-faded">
      <div>
        <div v-if="hasPermission('can.view.anything') && result.supplier" class="text-body2 text-uppercase text-weight-medium text-blue-grey-5 flex items-center">
          <q-icon size="16px" name="flight" /> &nbsp;&nbsp;{{ result.supplier }}
        </div>
        <div v-if="result.baggage_pieces" class="text-body2 text-blue-grey-5 flex items-center">
          <q-icon size="16px" name="work" /> &nbsp;&nbsp;{{ result.baggage_pieces }}x {{ $t('baggage_pieces') }}
        </div>
        <sub v-if="hasPermission('can.view.anything')" class="text-uppercase text-weight-medium text-blue-grey-5 flex items-center">
          <q-chip v-for="(code, i) in result.fare_basis_codes" :key="i" :label="code" size="sm" dense square />
        </sub>
      </div>
      <div class="price-inner q-mt-sm" v-html="$t('from_price_per_adult', { price: result.price.text })" />
    </div>
  </q-card>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import journeySummary from './journey-summary'
export default {
  components: { journeySummary },
  mixins: [authentication],
  props: ['result'],
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    cardClass () {
      return this.stash.selected && this.stash.selected.pricing_token === this.result.pricing_token ? ['bg-primary-selected', 'shadow-3'] : null
    }
  },
  methods: {
    select () {
      this.$emit('click', this.result)
    }
  }
}
</script>

<style lang="stylus" scoped>

.result
  cursor pointer
  margin 18px 0 6px

.flight-journey
  padding-bottom 5px!important

.flight-journey:last-child
  border-top 1px solid #DCDCDC
  padding 16px!important
  padding-bottom 5px!important

.flight-journey:first-child
  border-top 0!important

.price
  border-top 1px solid #DCDCDC
  border-bottom-left-radius 0!important
  text-align right
  @media (min-width: 575px)
    border-top 0
    border-left 1px solid #DCDCDC
    text-align left

</style>
