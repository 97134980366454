export default {
  methods: {
    destinationChange (val) {
      this.parameters.destination = val
    },
    originChange (val) {
      this.parameters.origin = val
    }
  }
}
