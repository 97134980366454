<template>
  <div class="journey-summary row text-faded">
    <div class="col-2 q-pr-md">
      <m-image
        :src="vendorLogo"
        :title="vendor.name"
      />
    </div>
    <div class="col-10 row">
      <div class="col-6 row">
        <div class="col-3">
          <div class="text-black text-subtitle text-weight-medium">
            {{ departureTime }}
          </div>
          <div :title="journey.origin.name" class="text-caption text-weight-medium">
            {{ journey.origin.iata }}
          </div>
        </div>
        <div class="col-6 line-outer">
          <div class="bubble bubble-1" />
          <div class="line-inner" />
          <div class="bubble bubble-2" />
        </div>
        <div class="col-3">
          <div class="text-black text-subtitle text-weight-medium">
            {{ arrivalTime }}
          </div>
          <div :title="journey.destination.name" class="text-caption text-weight-medium">
            {{ journey.destination.iata }}
          </div>
        </div>
      </div>
      <div class="offset-1 col-5 text-body2">
        {{ duration }}<br>
        <span :class="{'text-green': journey.is_direct}">{{ stopsText }}</span>
      </div>
      <div class="col-12 q-my-sm text-caption">
        {{ journey.codeshare_info[0] || vendor.short_name }} &middot; {{ journey.class }} &middot; {{ journey.flight_numbers[0] }}
      </div>
    </div>
  </div>
</template>

<script>
import { Duration } from 'luxon'
import date from 'utils/date-time'
import { MImage } from 'components/'
export default {
  components: { MImage },
  props: ['journey'],
  computed: {
    vendor () {
      return this.journey.vendors[0]
    },
    vendorLogo () {
      return this.vendor.logo.replace('gif', 'png')
    },
    departureTime () {
      return date.toCivilTime(this.journey.depart.date)
    },
    arrivalTime () {
      return date.toCivilTime(this.journey.arrive.date)
    },
    stopsText () {
      return this.journey.is_direct ? this.$tc('flight.changes', 0) : this.$tc('num.stops', this.journey.number_of_stops)
    },
    duration () {
      const duration = Duration.fromObject({ seconds: this.journey.duration.value }).toFormat('h m')
      return `${duration.split(' ')[0]}${this.$t('abbreviation.hours')} ${duration.split(' ')[1]}${this.$t('abbreviation.minutes')}`
    }
  }
}
</script>

<style lang="stylus" scoped>
h6
  margin 0
.line-outer
  position relative
.line-inner
  height: 2px;
  background: #DCDCDC;
  top: 14px;
  left: 8px;
  right 8px
  position: absolute;

.bubble
  height: 6px;
  width: 6px;
  background: #DCDCDC;
  border-radius: 50%;
  position: absolute;

.bubble-1
  top: 12px;
  left: 8px

.bubble-2
  top 12px
  right 8px
</style>
