/**
 * Optimised exports of the world countries data for use with
 * Quasar Select components.
 */

import json from './countries.json'
import { orderBy } from 'lodash'

export const countries = orderBy(json, 'name')
  .filter(country => country.name)
  .map(country => {
    return {
      label: country.name,
      value: country.cca2
    }
  })

export const nationalities = orderBy(json, 'name')
  .filter(country => country.nationality)
  .map(country => {
    return {
      label: country.nationality,
      value: country.cca2
    }
  })

export const dialCodes = orderBy(json, 'name')
  .filter(country => country.idd)
  .map(country => {
    return {
      label: `+${country.idd} - ${country.name}`,
      country: country.name.toLowerCase(),
      value: country.idd
    }
  })
