<template>
  <q-page v-if="stash.summary" padding>
    <div class="row q-col-gutter-sm">
      <div class="col-12">
        <q-card class="col-12 bg-white">
          <q-card-section :class="`bg-primary`">
            {{ $t('booking.summary') }}
          </q-card-section>
          <m-banner :title="$t('flight.booking_for', { name: stash.parameters.traveller.label})" icon="person" square borderless />
          <q-card-section>
            <flight-detail :flight="stash.summary.outbound" :title="$t('flight.outbound')" />
            <flight-detail
              v-if="stash.summary.return"
              :flight="stash.summary.return"
              :title="$t('flight.return')"
            />
            <div v-if="stash.summary.mobilleo_booking_fee" class="row">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('booking.fee') }}</span>
              <span
                class="col-2 offset-1 text-grey-9 text-right"
              >{{ stash.summary.mobilleo_booking_fee.text }}</span>
            </div>
            <div class="row">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('net_amount') }}</span>
              <span
                class="col-2 offset-1 text-grey-9 text-right"
              >{{ stash.summary.total_flight_price.text }}</span>
            </div>
            <div class="row">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
              <span class="col-2 offset-1 text-grey-9 text-right">{{ stash.summary.vat.text }}</span>
            </div>
            <q-separator class="hr" />
            <div class="row summary-footer">
              <div v-if="stash.fareRules" class="col-4">
                <q-btn color="primary" outline :label="$t('flight.fare_rules')" @click="showFareRulesModal = true" />
              </div>

              <h6 class="text-bold offset-1 col text-grey-9 text-right">
                {{ $t('total_cost') }}
              </h6>
              <h6 class="text-bold col-2 offset-1 text-grey-9 text-right">
                {{ stash.summary.total_price.text }}
              </h6>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div v-if="stash.summary.ancillary_selected_text" class="col-12">
        <q-card class="bg-primary text-white">
          <q-card-section>
            {{ stash.summary.ancillary_selected_text }}
          </q-card-section>
        </q-card>
      </div>
      <div class="col-12">
        <m-attach-to-booking-request
          :suggested="stash.summary.suggested_journey_name"
          :username="stash.parameters.traveller.value"
          :journey="journey"
          :show-travel-type="travelPolicy && travelPolicy.flight.enabled === true"
          @change="(val) => $store.dispatch('ondemand/journey', val)"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
          @travelTypeChange="(val) => travelType = val"
        />
      </div>

      <payment-buttons
        :total-amount="stash.summary.total_price.amount"
        :requester="stash.parameters.requester"
        :traveller="stash.parameters.traveller || stash.parameters.requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="bookingToken"
        :travel-type="(travelPolicy && travelPolicy.flight.enabled === true) || !organisation ? travelType : 'Private'"
      />
      <m-terms />
    </div>

    <q-dialog v-model="showFareRulesModal">
      <q-card>
        <q-toolbar class="bg-primary shadow-2 row">
          <div class="col-2">
            <q-btn
              v-close-popup
              flat
              round
              dense
              icon="keyboard_arrow_left"
            />
          </div>
          <q-toolbar-title class="col-8 text-center">
            {{ $t('flight.fare_rules') }}
          </q-toolbar-title>
        </q-toolbar>

        <q-card-section class="scroll">
          <div class="fare-rules-text">
            {{ stash.fareRules }}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import store from 'store'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import flightDetail from './flight-detail'
import paymentButtons from 'pages/payments/payment-method-buttons'
import { MBanner, MAttachToBookingRequest, MTerms } from 'components/'
import { extras } from 'api/flights'
import i18n from 'i18n'

export default {
  name: 'OndemandFlightsSummary',
  components: {
    flightDetail,
    paymentButtons,
    MBanner,
    MAttachToBookingRequest,
    MTerms
  },
  mixins: [authentication],
  data () {
    return {
      showFareRulesModal: false,
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation'
    }),
    bookingToken () {
      return this.stash.summary.links.booking.split('/').pop()
    },
    isBookingForOther () {
      return this.user.username !== this.stash.parameters.traveller.value
    },
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { flight: { enabled: true } }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    const stash = store.getters['ondemand/stash']

    loading.start({
      message: i18n.t('loading.preparing.booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const response = await extras(stash.selected.pricing_token, stash.passengerInfo)

      store.dispatch('ondemand/journey', { name: response.data.outbound.destination.name })
      store.dispatch('ondemand/stash', { summary: response.data })
      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
hr
  width 100%
  margin-bottom 16px
button
  width 100%

span, .hr
  margin-top 16px

.summary-footer
  height 60px
  align-items center

.scroll
  overflow-y scroll

.fare-rules-text
  max-width 100%
  word-wrap break-word
  overflow-wrap break-word
  font-family Roboto
</style>
