<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <q-card square class="bg-white relative-position">
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_flying') }}
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="train-grid">
            <div class="row radio">
              <div class="col-6 center">
                <q-radio v-model="returning" :val="true" label="Return" />
              </div>
              <div class="col-6 center">
                <q-radio v-model="returning" :val="false" label="One way" />
              </div>
            </div>

            <m-location-search
              ref="pickUp"
              :query="autocomplete"
              :value="parameters.origin"
              :label="$t('going_from')"
              icon="mdi-airplane-takeoff"
              class="origin search-btn"
              :placeholder="$t('input.departure_airport')"
              no-shadow
              keep-open
              no-saved
              @selected="originSelect"
            />

            <q-btn
              flat
              class="icon swap"
              size="14px"
              icon="fa fa-exchange"
              color="primary"
              @click="swap"
            />

            <m-location-search
              ref="pickUp"
              :query="autocomplete"
              :value="parameters.destination"
              :label="$t('going_to')"
              icon="mdi-airplane-landing"
              class="destination search-btn"
              :placeholder="$t('input.arrival_airport')"
              no-shadow
              keep-open
              no-saved
              @selected="destinationSelect"
            />

            <m-date-field
              v-model="parameters.outbound"
              class="date"
              :min="departMin"
              :label="$t('date.travel')"
              :outlined="$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
            />

            <m-date-field
              v-if="returning"
              v-model="parameters.inbound"
              class="returning"
              :min="parameters.outbound"
              :label="$t('date.return')"
              :outlined="$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
            />

            <q-select
              v-model="parameters.class"
              class="adults"
              :options="cabinClasses"
              stack-label
              :label="$t('flight.class.cabin')"
              :outlined="$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
              map-options
              emit-value
            />

            <q-select
              v-model="parameters.max_changes"
              class="railcards"
              :options="changes"
              stack-label
              :label="$t('flight.maximum_changes')"
              :outlined="$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
              map-options
              emit-value
            />
          </div>
        </q-card-section>
      </q-card>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta">
        <m-cta-button
          :label="$tc('find.flight', 2)"
          :disabled="disabled"
          @ctaClick="submit"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import { airports } from 'api/flights'
import date from 'utils/date-time'
import model from './model'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import { MLocationSearch, MUnifiedUsers, MDateField, MCtaButton } from 'components/'

export default {
  name: 'OndemandFlights',
  components: {
    MDateField,
    MLocationSearch,
    MUnifiedUsers,
    MCtaButton
  },
  mixins: [authentication, searchLocation],
  data () {
    return {
      departMin: date.newDate(),
      parameters: model(),
      cabinClasses: [
        {
          label: this.$t('flight.class.economy'),
          value: 'Economy'
        },
        {
          label: this.$t('flight.class.premium_economy'),
          value: 'PremiumEconomy'
        },
        {
          label: this.$t('flight.class.business'),
          value: 'Business'
        },
        {
          label: this.$t('flight.class.first'),
          value: 'First'
        }
      ],
      changes: [
        {
          label: this.$tc('flight.changes', 0),
          value: 'direct'
        },
        {
          label: this.$tc('flight.changes', 1),
          value: '1'
        },
        {
          label: this.$tc('flight.changes', 2, { n: '2+' }),
          value: null
        }
      ],
      fromListOptions: []
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    returning: {
      get () {
        return !!this.parameters.inbound
      },
      set (value) {
        this.parameters.inbound = value
          ? date.addToDate(this.parameters.outbound, { days: 2 })
          : null
      }
    },
    locationsSet () {
      return this.parameters.origin && this.parameters.destination
    },
    disabled () {
      return !(
        this.locationsSet &&
        this.parameters.traveller &&
        this.parameters.requester
      )
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    'parameters.outbound' (val) {
      if (val > this.parameters.inbound && this.returning) {
        this.parameters.inbound = date.newDate(val)
      }
    },
    '$route.query.origin': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    originSelect (value) {
      this.parameters.origin = value
    },
    destinationSelect (value) {
      this.parameters.destination = value
    },
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-flights-outbound' })
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    },
    airports,
    async autocomplete (terms) {
      const { data } = await airports({ query: terms })
      return data.map(airport => {
        return {
          label: airport.name,
          value: airport.iata,
          icon: 'local_airport',
          color: 'red-5',
          append: airport.iata
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>

.center
  justify-content center
  display flex
</style>
