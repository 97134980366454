<template>
  <div class="flight-details">
    <div
      class="col-12 text-uppercase"
      style="margin-bottom: 8px"
    >
      {{ title }}
    </div>
    <div class="row items-center justify-center text-center">
      <div class="col-2">
        <m-image :src="vendor.logo" width="32px" />
        <br>
        <sub class="text-faded">{{ vendor.short_name }}</sub>
      </div>
      <div class="col-1">
        {{ origin.iata }}
        <sub>{{ depart | toCivilTime }}</sub>
      </div>
      <div class="col-6">
        <sub class="text-green">{{ stops }}</sub>
        <div class="line" />
        <sub class="text-teal-5">{{ duration }}</sub>
      </div>
      <div class="col-1">
        {{ destination.iata }}
        <sub>{{ arrive | toCivilTime }}</sub>
      </div>
      <div class="col-2">
        {{ flightNumber }}
        <br>
        <sub class="text-faded">{{ terminal }}</sub>
      </div>
    </div>
    <div class="row items-center justify-center text-center">
      <div class="col-4">
        <sub class="text-faded">Selling class</sub>
        <br>
        {{ flight.class }}
      </div>
      <div class="col-4">
        <sub class="text-faded">Date</sub>
        <br>
        {{ depart | toShortDate }}
      </div>
    </div>
    <q-separator style="margin:16px 0" />
  </div>
</template>

<script>
import date from 'utils/date-time'
import mImage from 'components/MImage'
export default {
  name: 'OndemandFlightsSummaryFlightDetail',
  components: {
    mImage
  },
  filters: {
    toCivilTime: date.toCivilTime,
    toShortDate: date.toShortDate
  },
  props: {
    flight: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    stops () {
      return this.flight.is_direct
        ? 'Direct'
        : this.number_of_stops > 1
          ? `${this.number_of_stops} stops`
          : '1 stop'
    },
    vendor () {
      return this.flight.vendors[0]
    },
    origin () {
      return this.flight.origin
    },
    destination () {
      return this.flight.destination
    },
    depart () {
      return this.flight.depart.date
    },
    arrive () {
      return this.flight.arrive.date
    },
    duration () {
      return this.flight.duration.text
    },
    flightNumber () {
      return this.flight.flight_numbers[0]
    },
    terminal () {
      return this.flight.origin_terminal
    }
  }
}
</script>

<style lang="stylus" scoped>
.flight-details
  margin 16px 0
.line
  position relative
  margin: 8px
  height: 2px
  background-color currentColor
  &:before,
  &:after
    content ""
    position absolute
    width 10px
    height 10px
    background currentColor
    border-radius 100%
    top -4px
  &:before
    left 0
  &:after
    right 0
</style>
