<template>
  <q-drawer
    v-model="show"
    elevated
    side="right"
  >
    <div
      v-if="filters.sort_by"
      class="column items-stretch"
    >
      <q-stepper
        v-if="!this.$q.platform.is.mobile"
        ref="stepper"
        v-model="currentStep"
        vertical
        header-nav
      >
        <q-step
          name="ondemand-flights-outbound"
          :title="$t('flight.choose.outbound')"
          :header-nav="navigatable.includes('ondemand-flights-outbound')"
          :done="navigatable.includes('ondemand-flights-outbound')"
        >
          <template v-if="stash.selected && stash.selected.outbound">
            <q-btn @click="$router.push({name: 'ondemand-flights-passenger-information'})">
              {{ $t('continue') }}
            </q-btn>
          </template>
        </q-step>
        <q-step
          name="ondemand-flights-passenger-information"
          :title="$t('passenger_information')"
          :header-nav="navigatable.includes('ondemand-flights-passenger-information')"
          :done="navigatable.includes('ondemand-flights-passenger-information')"
        >
          <q-btn @click="$router.push({name: 'ondemand-flights-extras'})">
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-flights-extras"
          :title="$t('flight.choose.extras')"
          :header-nav="navigatable.includes('ondemand-flights-extras')"
          :done="navigatable.includes('ondemand-flights-extras')"
        >
          <q-btn @click="$router.push({name: 'ondemand-flights-summary'})">
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-flights-summary"
          :title="$t('summary')"
          caption="Review and submit"
          :header-nav="navigatable.includes('ondemand-flights-summary')"
          :done="navigatable.includes('ondemand-flights-summary')"
        />
        <q-step
          name="ondemand-flights-payment-card"
          :title="$t('payment')"
          :header-nav="navigatable.includes('ondemand-flights-payment-card')"
          :done="navigatable.includes('ondemand-flights-payment-card')"
        />
        <q-step
          name="ondemand-flights-confirmation"
          :title="$t('confirmation')"
          :header-nav="navigatable.includes('ondemand-flights-confirmation')"
          :done="navigatable.includes('ondemand-flights-confirmation')"
        />
      </q-stepper>
      <q-list
        v-if="$route.name === 'ondemand-flights-outbound' || $route.name === 'ondemand-flights-return'"
        no-border
      >
        <q-item-label header>
          {{ $t('filter_results') }}
        </q-item-label>
        <q-item>
          <q-item-section>
            <q-select
              v-model="max_changes"
              :options="changes"
              stack-label
              :label="$t('flight.maximum_changes')"
              map-options
              emit-value
            />
          </q-item-section>
        </q-item>
        <q-item v-if="airlineSelectOptions.length">
          <q-item-section>
            <q-select
              v-model="localFilters.airlines"
              :options="airlineSelectOptions"
              multiple
              stack-label
              :label="$t('airlines')"
              map-options
              emit-value
            />
          </q-item-section>
        </q-item>
        <q-item-label header>
          {{ $t('flight.outbound_time') }}
        </q-item-label>
        <q-item>
          <q-item-section>
            <q-field :label="$t('going_from_place', { place: labels.origin})" stack-label borderless>
              <template v-slot:prepend>
                <q-icon name="flight_takeoff" />
              </template>
              <template v-slot:control>
                {{ outboundDeparture.min | time }} - {{ outboundDeparture.max | time }}
                <q-range v-model="outboundDeparture" :min="0" :max="24" :step="1" markers snap />
              </template>
            </q-field>
            <q-field :label="$t('arrive_at_place', { place: labels.destination})" stack-label borderless>
              <template v-slot:prepend>
                <q-icon name="flight_land" />
              </template>
              <template v-slot:control>
                {{ outboundArrival.min | time }} - {{ outboundArrival.max | time }}
                <q-range v-model="outboundArrival" :min="0" :max="24" :step="1" markers snap />
              </template>
            </q-field>
          </q-item-section>
        </q-item>
        <template v-if="stash.parameters.inbound">
          <q-item-label header>
            {{ $t('time.return') }}
          </q-item-label>
          <q-item>
            <q-item-section>
              <q-field :label="$t('going_from_place', { place: labels.destination})" stack-label borderless>
                <template v-slot:prepend>
                  <q-icon name="flight_takeoff" />
                </template>
                <template v-slot:control>
                  {{ inboundDeparture.min | time }} - {{ inboundDeparture.max | time }}
                  <q-range v-model="inboundDeparture" :min="0" :max="24" :step="1" markers snap />
                </template>
              </q-field>
              <q-field :label="$t('arrive_at_place', { place: labels.origin})" stack-label borderless>
                <template v-slot:prepend>
                  <q-icon name="flight_land" />
                </template>
                <template v-slot:control>
                  {{ inboundArrival.min | time }} - {{ inboundArrival.max | time }}
                  <q-range v-model="inboundArrival" :min="0" :max="24" :step="1" markers snap />
                </template>
              </q-field>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { padStart, debounce } from 'lodash'
export default {
  name: 'FlightsSidebar',
  filters: {
    time (value) {
      return value === 24
        ? '23:59'
        : `${padStart(value, 2, '0')}:00`
    }
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile,
      outboundArrival: {
        min: 0,
        max: 24
      },
      outboundDeparture: {
        min: 0,
        max: 24
      },
      inboundArrival: {
        min: 0,
        max: 24
      },
      inboundDeparture: {
        min: 0,
        max: 24
      },
      changes: [
        {
          label: this.$tc('flight.changes', 0),
          value: 'direct'
        },
        {
          label: this.$tc('flight.changes', 1),
          value: '1'
        },
        {
          label: this.$tc('flight.changes', 2, { num: '2+' }),
          value: null
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      filters: 'ondemand/filters',
      activeFilters: 'ondemand/activeFilters',
      ui: 'ui'
    }),
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    max_changes: {
      get () {
        return this.stash.parameters.max_changes
      },
      set (value) {
        this.$store.dispatch('ondemand/stashProp', {
          path: 'stash.parameters.max_changes',
          value
        })
      }
    },
    localFilters: {
      get () {
        return this.activeFilters
      },
      set (value) {
        this.$store.dispatch('ondemand/activeFilters', value)
      }
    },
    airlineSelectOptions () {
      return this.filters.airlines.map(f => ({
        label: f.name,
        value: f.code
      }))
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-flights-confirmation':
        availablePaths.push('ondemand-flights-payment-card')
      case 'ondemand-flights-payment-card':
        availablePaths.push('ondemand-flights-summary')
      case 'ondemand-flights-summary':
        availablePaths.push('ondemand-flights-extras')
      case 'ondemand-flights-extras':
        availablePaths.push('ondemand-flights-passenger-information')
      case 'ondemand-flights-passenger-information':
        availablePaths.push('ondemand-flights-outbound')
        break
      }
      return availablePaths
    },
    labels () {
      const origin = this.stash.parameters.origin
      const destination = this.stash.parameters.destination
      return {
        origin: origin.value.length <= 3 ? origin.value : origin.label,
        destination: destination.value.length <= 3 ? destination.value : destination.label
      }
    }
  },
  watch: {
    outboundArrival: debounce(function (value) {
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.outboundArrivalMin',
        value: value.min
      })
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.outboundArrivalMax',
        value: value.max
      })
    }, 500),
    outboundDeparture: debounce(function (value) {
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.outboundDepartureMin',
        value: value.min
      })
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.outboundDepartureMax',
        value: value.max
      })
    }, 500),
    inboundArrival: debounce(function (value) {
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.inboundArrivalMin',
        value: value.min
      })
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.inboundArrivalMax',
        value: value.max
      })
    }, 500),
    inboundDeparture: debounce(function (value) {
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.inboundDepartureMin',
        value: value.min
      })
      this.$store.dispatch('ondemand/stashProp', {
        path: 'stash.parameters.inboundDepatureMax',
        value: value.max
      })
    }, 500)
  }
}
</script>

<style lang="stylus" scoped>
  .subheader
    font-size 12px
    font-weight 400
    padding-bottom 0
    min-height 0
  .overview-price
    padding 8px
    .total
      font-size 1em
    .price
      margin 4px 0
      font-size 1.6em
    .people
      font-size 0.8em
</style>
