import http from './http'
import date from 'utils/date-time'

export function airports (params) {
  return http.get(`/airports`, {
    params: {
      metropolitan: 1,
      ...params
    }
  })
}

export function search (params) {
  const parameters = {
    origin: params.origin.value,
    outbound_flight_time_from: date.toCivilDate(params.outbound),
    outbound_flight_time_type: 'departure',
    ...(params.inbound && { inbound_flight_time_from: date.toCivilDate(params.inbound) }),
    ...(params.inbound && { inbound_flight_time_type: 'departure' }),
    destination: params.destination.value,
    adults: params.adults || 1,
    class: params.class || 'Economy',
    user: params.user,
    ...(params.requester && { requester: params.requester }),
    ...(params.max_changes === 'direct' && { direct: 1 }),
    ...(params.max_changes === '1' && { max_changes: 1 })
  }

  return http.get(`/search/flights2`, { params: parameters })
}

export function extras (id, params) {
  return http.post(`/search/flights2/${id}`, params)
}

export function summary (id) {
  return http.get(`/search/flights2/${id}`)
}

export function countries () {
  return http.get(`/countries?limit=220`)
}
