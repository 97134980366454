<template>
  <q-page v-if="info" padding>
    <div class="row extras">
      <div class="col-sm-12">
        <q-card class="bg-white">
          <q-card-section :class="`bg-primary`">
            {{ $t('flight.extras.optional') }}
          </q-card-section>
          <q-card-section class="row q-col-gutter-sm">
            <div class="col-12">
              <q-select
                v-model="info.seat_id"
                :options="seats"
                stack-label
                :label="$t('flight.extras.seats')"
                class="row"
                map-options
                emit-value
              />
            </div>
            <div class="col-12">
              <q-select
                v-if="baggages.length > 0"
                v-model="info.baggage_id"
                :options="baggages"
                stack-label
                :label="$t('flight.extras.additional_baggage')"
                map-options
                emit-value
              />
            </div>
          </q-card-section>
          <m-banner v-if="summary.ancillary_selection_text" :message="summary.ancillary_selection_text" :rounded="false" borderless />
        </q-card>
      </div>
    </div>
    <div class="cta">
      <m-cta-button
        :label="$t('continue')"
        @ctaClick="$router.push({name: 'ondemand-flights-summary'})"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import { extras } from 'api/flights'
import { mapGetters } from 'vuex'
import store from 'store'
import { MBanner, MCtaButton } from 'components/'
import i18n from 'i18n'

export default {
  name: 'OndemandFlightsExtras',
  components: {
    MBanner,
    MCtaButton
  },
  data () {
    return {
      summary: null,
      info: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    seats () {
      let seats = this.summary.seat_options
        .map(seat => {
          return {
            label: seat.text,
            value: seat.id
          }
        })
      return seats
    },
    baggages () {
      let baggages = this.summary.baggage_options
        .map(bag => {
          return {
            label: bag.text,
            value: bag.id
          }
        })
      return baggages
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.checking.available_extras'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    const stash = store.getters['ondemand/stash']

    try {
      const response = await extras(stash.selected.pricing_token, stash.passengerInfo)

      next(vm => {
        vm.summary = response.data
        vm.info = stash.passengerInfo

        store.dispatch('ondemand/stash', { error: null })
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  beforeRouteLeave (to, from, next) {
    store.dispatch('ondemand/stash', { passengerInfo: this.info })
    next()
  }
}
</script>

<style lang="stylus" scoped>
.extras
  max-width 768px
  margin auto
.q-card-primary + .q-card-section
  padding-top 16px
</style>
